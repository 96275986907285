import React, { Component } from "react";
import "../scss/_Header.scss";
import LogoSmall from "../img/logo-small.png";
import Logo from '../img/logo.jpg';
import Nav from "./Nav";
import Close from '../img/close-icon.svg';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
    this.handleclick = this.handleclick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.toggleLogo = this.toggleLogo.bind(this);
  }

  componentDidMount = () => {
    this.toggleLogo();
  }

  handleclick() {
    this.setState({ open: true });
  }

  closeDialog = () => {
    this.setState({ open: false });
  }

  toggleLogo = () => {
    const logo = document.getElementById('logo');

    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 100) {
          logo.style.display = 'none';
          logo.style.animationName = 'hideLogo';
          logo.style.animationDuration = '0.5s';
        }
        else {
          logo.style.display = 'block';
          logo.style.animationName = 'showLogo';
          logo.style.animationDuration = '0.5s';
        }
      })
    }

  }

  render() {

    let { open } = this.state;

    return (
      <React.Fragment>
        <header className="main-header">
          <div className="main-heading">
            <a href="/">     
              <img src={LogoSmall} alt="Logo Purcell Electrical" className="small-logo" />
              <img src={Logo} alt="Logo Purcell Electrical" className="large-logo" id="logo" />
            </a>
          </div>
          <div
            id="hamburger-icon"
            className="hamburger-icon"
            onClick={this.handleclick}
          />
        </header>
        {!open &&
          <div id="main-nav" className="dialog-nav close"  onClick={this.closeDialog}>
            <img src={Close} alt="Close navigation bar" className="close-icon" onClick={this.closeDialog} />
            <Nav />
          </div>
        }
        {open &&
          <div id="main-nav" className="dialog-nav open"  onClick={this.closeDialog}>
            <img src={Close} alt="Close navigation bar" className="close-icon" onClick={this.closeDialog} />
            <Nav />
          </div>
        }

        <div className="hero-image" id="background"></div>
        <div className="hero-text">
          <h2>Each job no matter the size is treated with the same values.</h2>
          <a href="#contact" role="button" className="btn-primary">Contact Us <i className="fas fa-paper-plane"></i></a>
        </div>
        <i className="fas fa-chevron-down scroll-icon"></i>
      </React.Fragment>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import Header from './Header';
import '../scss/_App.scss';
import Services from './Services';
import Features from './Features';
import Reviews from './Reviews';
import Contact from './Contact';
import Footer from './Footer';
import Social from './Social';

class App extends Component {

  componentDidMount = () => {
    window.addEventListener('scroll', () => {
      let scrolled = window.pageYOffset;
      const background = document.getElementById('background');
      background.style.backgroundPosition = '0 '+ (- (scrolled  * 0.3) + 'px');
    })
  }

  render() {
    return (
      <div className="App">
        <a href="#main" className="skip-link">Skip to main content</a>
        <Header />
        <main id="main">
          <Services />
          <Features />
          <Reviews />
          <Contact />
        </main>
        <Social />
        <Footer />
      </div>
    );
  }
}

export default App;

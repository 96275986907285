import React, { Component } from "react";
import "../scss/_Social.scss";

class Social extends Component {
  render = () => {
    return (
      <nav className="social">
        <a
          href="https://www.facebook.com/PurcellElectrical/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
          aria-label="Open Purcell Electrical profile on Facebook"
        >
          <i className="fab fa-facebook-square" />
        </a>
        <a
          href="https://twitter.com/purcell_elec"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
          aria-label="Open Purcell Electrical profile on Twitter"
        >
          <i className="fab fa-twitter-square" />
        </a>
      </nav>
    );
  };
}

export default Social;

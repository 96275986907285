import React, { Component } from "react";
import "../scss/_Map.scss";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: ""
    };
    this.loadJS = this.loadJS.bind(this);
    this.initMap = this.initMap.bind(this);
    this.addMarker = this.addMarker.bind(this);
  }

  componentDidMount = () => {
    this.loadJS(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyArUfjcdqlYtdphPDIcr7Rr2QRpzQQWba8&callback=initMap"
    );
    //Create map on load
    window.initMap = this.initMap;
  };

  loadJS = src => {
    const ref = window.document.getElementsByTagName("script")[0];
    const script = window.document.createElement("script");
    script.src = src;
    script.async = true;
    script.defer = true;
    ref.parentNode.insertBefore(script, ref);
  };

  initMap = () => {
    const styles = [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            gamma: "1.00"
          }
        ]
      },
      {
        featureType: "administrative.locality",
        elementType: "labels",
        stylers: [
          {
            color: "#7e0003"
          }
        ]
      },
      {
        featureType: "administrative.neighborhood",
        elementType: "labels",
        stylers: [
          {
            color: "#e3000e"
          }
        ]
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            lightness: "65"
          },
          {
            saturation: "-100"
          },
          {
            hue: "#ff0000"
          }
        ]
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            saturation: "-100"
          },
          {
            lightness: "80"
          }
        ]
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "poi.attraction",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            color: "#dddddd"
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            color: "#dddddd"
          }
        ]
      },
      {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            color: "#eeeeee"
          }
        ]
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ba5858"
          },
          {
            saturation: "-100"
          }
        ]
      },
      {
        featureType: "transit.station",
        elementType: "all",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ba5858"
          },
          {
            visibility: "simplified"
          }
        ]
      },
      {
        featureType: "transit.station",
        elementType: "labels.icon",
        stylers: [
          {
            hue: "#ff0036"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            visibility: "simplified"
          },
          {
            color: "#dddddd"
          }
        ]
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ba5858"
          }
        ]
      }
    ];
    let map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      center: { lat: 51.4465027, lng: 0.0611426 },
      styles: styles,
      mapTypeControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      }
    });
    this.setState({ map: map });

    window.google.maps.event.addListenerOnce(map, "idle", () => {
      document.getElementsByTagName("iframe")[0].title = "Google  Maps";
    });

    this.addMarker();
  };

  addMarker = () => {
    const { map } = this.state;
    var myLatlng = { lat: 51.476383, lng: 0.088804 };

    let marker = new window.google.maps.Marker({
      map: map,
      position: myLatlng,
      title: "Purcell Electrical Ltd",
      animation: window.google.maps.Animation.DROP
    });

    marker.setMap(map);
  };
  render() {
    return <div ref="map" id="map" className="map" role="application" />;
  }
}

export default Map;

import React, { Component } from "react";
import "../scss/_Services.scss";

class Services extends Component {
  componentDidMount = () => {
    window.addEventListener("scroll", () => {
      let scrolled = window.pageYOffset;
      const background = document.getElementById("services");
      background.style.backgroundPosition = scrolled * 0.7 + "px 0";
    });
  };

  render() {
    return (
      <section id="services" className="services">
        <header className="header services-header">
          <h1 className="heading">Our Services</h1>
        </header>
        <div className="container services-container">
          <div className="service-card">
            <i className="fas fa-tools" />
            <h2>Appliance Install</h2>
            <ul className="service-list">
              <li>Electric Hob &amp; Oven</li>
              <li>Electric Heater</li>
              <li>Electric Boiler</li>
              <li>Electric Shower</li>
              <li>LED Strip Lighting</li>
              <li>Chandeliers</li>
              <li>Video Door Bell</li>
              <li>Motion Sensored Floodlights</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-home" />
            <h2>Residential</h2>
            <ul className="service-list">
              <li>Full and Partial Rewire</li>
              <li>Maintenance</li>
              <li>Fault Finding</li>
              <li>Consumer Unit/Fuse Board Upgrades</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="far fa-check-square" /> <h2>Certification</h2>
            <ul className="service-list">
              <li>Domestic and Commercial Condition Report (EICR) </li>
              <li>Installations</li>
              <li>Minor Works</li>
              <li>Landlords Home buyers/Sellers Certificates</li>
              <li>Periodic Inspection</li>
            </ul>
          </div>
          <div className="service-card">
            <i className="fas fa-burn" />
            <h2>Gas</h2>
            <ul className="service-list">
              <li>
                Associated with trading Gas Safe Partners, for all plumbing and
                gas needs
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;

import React, { Component } from "react";
import "../scss/_Reviews.scss";
import CheckATrade from "../img/checkatrade-logo.png";
import MyBuilder from "../img/mybuilder-logo.png";

class Reviews extends Component {
  render() {
    return (
      <section id="reviews" className="reviews">
        <header className="header reviews-header">
          <h1 className="heading">Learn what clients say about us</h1>
        </header>
        <div className="container reviews-container">
          <a
            href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx"
            target="_blank"
            rel="noopener noreferrer"
            className="reviews-card"
          >
            <h2 className="review-title">Installing an extractor fan</h2>
            <p className="review">
              Best electrician by far! If I could give 11 out of 10 I would.
              Anthony's work is exceptionally impressive. His a guy who knows
              his stuff without a benefit of doubt. Super friendly, great sense
              of humour and communication is outstanding; answers any questions,
              and keeps you well informed of what's happening and what needs to
              be done. Clean, tidy, efficient and professional. His tradesmen
              skills are rare this day and age, to find someone as reliable as
              him is almost impossible. If there's any other electrical jobs,
              without a doubt I will contact him. Highly recommended!
            </p>
            <p className="review-date">8 April 2019</p>
          </a>
          <a
            href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx"
            target="_blank"
            rel="noopener noreferrer"
            className="reviews-card"
          >
            <h2 className="review-title">
              New heating system in classrooms/ complete rewire of electrics in
              classrooms/ new cabling to support new system
            </h2>
            <p className="review">
              Anthony did an amazing job. He kept me completely informed as the
              project developed, went above and beyond to give the jobs a
              fantastic finish, and left the site significantly cleaner than he
              found it! I highly recommend Anthony and will be using him for all
              of our electrical requirements in the future.
            </p>
            <p className="review-date">10 February 2019</p>
          </a>
          <div className="reviews-more">
            <img src={CheckATrade} alt="Check a Trade Logo" />
            <p>
              We are proud members of Checkatrade and have received 100%
              Positive Feedback Reviews
            </p>
            <a
              href="https://www.checkatrade.com/PurcellElectrical/Reviews.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more reviews on Checkatrade{" "}
              <i className="fas fa-angle-double-right" />
            </a>
          </div>
          <div className="reviews-more">
            <img src={MyBuilder} alt="My Builder Badge" className="my-builder" />
            <p>We are proud to have received 100% Positive Feedback Reviews from previous My Builder clients.</p>
            <a
              href="https://www.mybuilder.com/profile/view/elecplumb_services"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more reviews on My Builder{" "}
              <i className="fas fa-angle-double-right" />
            </a>
          </div>
          <a
            href="https://www.mybuilder.com/profile/view/elecplumb_services"
            target="_blank"
            rel="noopener noreferrer"
            className="reviews-card"
          >
            <h2 className="review-title">Replacing a fuse switch - trouble shoot issue</h2>
            <p className="review">
            Anthony was great! He arrived on time and was very thorough to trouble shoot and diagnose the issue. He cleaned up afterwards and was very courteous. Would hire him again without question!
            </p>
            <p className="review-date">11 December 2017</p>
          </a>
          <a
            href="https://www.mybuilder.com/profile/view/elecplumb_services"
            target="_blank"
            rel="noopener noreferrer"
            className="reviews-card"
          >
            <h2 className="review-title">Additional Socket</h2>
            <p className="review">
            Anthony did a really good job and also was very accommodating staying late to finish the job in our office. Communication was great throughout too.
            </p>
            <p className="review-date">11 October 2017</p>
          </a>
        </div>
        
      </section>
    );
  }
}

export default Reviews;

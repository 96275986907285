import React, { Component } from 'react';
import '../scss/_Footer.scss';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      year: ''
    };
  }

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    this.setState({ year: year });
  }
  
  render() {
    const { year } = this.state;
    return (
      <footer id="footer" className="footer">
        <p>Copyright &copy; {year} Purcell Electrical Ltd.</p>
        <p>Designed &amp; developed by <a href="https://mywebgraphicdesign.com" target="_blank" rel="noopener noreferrer">AS</a></p>
      </footer>
    );
  }
}

export default Footer;
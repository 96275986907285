import React, { Component } from "react";
import "../scss/_Contact.scss";
import ContactForm from "./ContactForm";
import Map from "./Map";
import Logo from '../img/ukas-logo.jpg';
import Gas from '../img/gas-safe-register.jpg';
import Houzz from '../img/houzz.png';

class Contact extends Component {

  componentDidMount = () => {
    window.addEventListener('scroll', () => {
      let scrolled = window.pageYOffset;
      const background = document.getElementById('contact');
      background.style.backgroundPosition = ((scrolled  * 0.2) + 'px 0');
    })
  }

  render = () => {
    return (
      <section id="contact" className="contact">
        <header className="header contact-header">
          <h1 className="heading">Contact us</h1>
        </header>

        <div className="contact-container">
          <div className="contact-form-container">
            <ContactForm />
          </div>

          <div className="contact-details">
            <p>
              Give us a call, book a call-back at a time of your convenience or
              leave your details below. We’ll get right back to you.
            </p>
            <hr className="contact-line-break" />
            <h3>Purcell Electrical Ltd.</h3>
            <p>
              <i className="fas fa-map-marker-alt fa-fw contact-address" />33, Alabama
              Street
              <br />
              <span className="address-more">Plumstead SE18 2SH</span>
            </p>
            <p>
              <i className="fas fa-phone contact-phone fa-fw" />0 800 002 5174
              <br/>
              <span className="address-more">0 754 793 8119</span>
            </p>
            <p>
              <i className="fas fa-at contact-email fa-fw" />
              purcellelectrical@hotmail.co.uk
            </p>
            <hr className="contact-line-break" />
            <img src={Logo} alt="Governent Approved Contractor" className="logo" />
            <img src={Gas} alt="Gas Safe Register" className="logo" />
            <img src={Houzz} alt="Houzz" className="logo" />
          </div>
        </div>

        {navigator.onLine && <Map />}

        <div className="coverage">
          <h3 className="heading">Our coverage Area</h3>
          <ul className="coverage-list">
            <li>Woolwich</li>
            <li>Plumstead</li>
            <li>Abbey Wood</li>
            <li>Thamesmead</li>
            <li>Greenwich</li>
            <li>Deptford</li>
            <li>Lewisham</li>
            <li>East London</li>
            <li>South East London</li>
            <li>Kent</li>
          </ul>
        </div>
      </section>
    );
  };
}

export default Contact;

import React, { Component } from "react";
import "../scss/_ContactForm.scss";
import axios from "axios";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  onHandleSubmit = e => {
    e.preventDefault();
    const firstName = document.getElementById("name").value;
    const lastName = document.getElementById("surname").value;
    const email = document.getElementById("email").value;
    const tel = document.getElementById("telephone").value;
    const message = document.getElementById("message").value;
    axios({
      method: "POST",
      url: "/send",
      data: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        tel: tel,
        messsage: message
      }
    }).then(response => {
      if (response.data.msg === "success") {
        this.setState({ message: "success" });
        setTimeout(() => {
          this.resetForm();
        }, 1000);
      } else if (response.data.msg === "fail") {
        this.setState({ message: "fail" });
      }
    });
  };

  resetForm = () => {
    document.getElementById("contact-form").reset();
  };

  render = () => {
    const { message } = this.state;
    return (
      <div>
        {message === "success" && (
          <p className="message-success" id="success">
            Your message has been sent successfully. We will get back to you as soon as possible.
          </p>
        )}

        {message === "fail" && (
          <p className="message-failed" id="fail">
            There ws a problem with sending your email. Plaese try again.
          </p>
        )}

        <form
          className="contact-form"
          id="contact-form"
          onSubmit={this.onHandleSubmit}
          method="POST"
          action="/send"
        >
          <label htmlFor="name">Name*</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
            required
          />
          <label htmlFor="surname">Last name</label>
          <input
            type="text"
            name="surname"
            id="surname"
            placeholder="Your last name"
          />
          <label htmlFor="email">E-mail*</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="example@example.com"
            required
          />
          <label htmlFor="telephone">Telephone*</label>
          <input
            type="tel"
            name="telephone"
            id="telephone"
            placeholder="123456789"
            required
          />
          <label htmlFor="message">Message*</label>
          <textarea
            name="message"
            id="message"
            rows="5"
            placeholder="Write your message"
            required
          />
          <div className="row">
            <input
              type="checkbox"
              name="consent"
              id="consent"
              className="consent-checkbox"
            />
            <label htmlFor="consent">
              I hereby consent to my personal data being processed by Purcell
              Electrical Ltd. for the purpose of communication between me and
              company.*
            </label>
          </div>
          <button type="submit" className="btn-primary btn-submit">
            Send message
            <i className="fas fa-paper-plane" />
          </button>
        </form>
      </div>
    );
  };
}

export default ContactForm;
